<template>
    <component :is="componentPath"></component>
</template>

<script>
    import {ref} from "vue";
    import {useRouter} from "vue-router";

    export default {
        name: "MBCourseContainer",
        props: {
            pathSegment: String,
            includeId: Boolean
        },
        setup(props) {
            const componentPath = ref( "" );
            const router = useRouter();

            const loadPath = (path) => {

                let lastSegment = path.substring(path.lastIndexOf('/') + 1)

                let id = props.includeId ? lastSegment : ''
                import("@/components/Membership/"+props.pathSegment+id+".vue").then(val => {
                    componentPath.value = val.default;
                    // context.emit("loaded", true);
                }).catch(() => {
                    router.push("/404")
                });
            }

            return { componentPath,  loadPath };
        },
        created() {
            this.loadPath(this.$route.path)
        },
        watch: {
            $route(to) {
                if (to.path.indexOf('courses/')>=0) {
                    this.loadPath(to.path)
                }
            }
        }
    }
</script>

<style>

</style>